import { useTitle } from 'react-use';

import { type BreadcrumbNode } from '../../components/Breadcrumbs';
import {
  GameCenterContextProvider,
  type GameLikeMode,
} from '../../components/Game/GameCenter';
import { HomeBreadcrumb } from '../../components/Game/GameCenter/Utilities';
import { NotificationCenter } from '../../components/Notification';
import { useInstance } from '../../hooks/useInstance';
import {
  apiService,
  type GamesResponse,
  type Paginator,
} from '../../services/api-service';
import { type Game } from '../../types/game';
import { virtualTags } from '../../types/tag';
import { makeTitle } from '../../utils/common';
import { MinigameContextProvider } from './Context';
import { GameCRUDUtility } from './CRUDUtility';
import { Library } from './Library';
import { MyGames } from './MyGames';

export function getPaginator(tagId: number): Paginator<GamesResponse, Game> {
  if (tagId === virtualTags.untagged.id) {
    return apiService.game.getUntaggedPrimeGames();
  }
  return apiService.game.getGamesByTagId(tagId);
}

export function MinigameCenter(): JSX.Element {
  useTitle(makeTitle('Minigames'));
  return (
    <div className='w-full h-full'>
      <MyGames />
      <Library />
    </div>
  );
}

export const Wrapper = (props: {
  mode: GameLikeMode;
  routePrefix: string;
  children: React.ReactNode;
}): JSX.Element => {
  const breadcrumb = useInstance<BreadcrumbNode>(() => ({
    key: 'Minigames',
    node: <HomeBreadcrumb label='Minigames' to={props.routePrefix} />,
  }));

  return (
    <GameCenterContextProvider>
      <MinigameContextProvider
        embed={false}
        mode={props.mode}
        breadcrumb={breadcrumb}
        routePrefix={props.routePrefix}
      >
        {props.children}

        <GameCRUDUtility />
        <NotificationCenter
          className='!w-108'
          position='bottom-right'
          toastClassName='rounded my-2'
        />
      </MinigameContextProvider>
    </GameCenterContextProvider>
  );
};
