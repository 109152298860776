import pluralize from 'pluralize';
import React from 'react';

import { useMinigameContext } from '../../../pages/Minigame/Context';
import { type Game } from '../../../types/game';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { GameActionSheet } from '../GameActionSheet';
import { OndVersionChecks } from '../OndVersionChecks';
import { GameCover } from '../Utilities';
import { useGameLikeWorkspace } from './Context';
import { useGameLikeActionSheetSettings } from './hooks';

export function MiniGameCard(props: {
  game: Game;
  myBadge?: boolean;
  onGameClick?: (game: Game) => void;
  disableAction?: boolean;
}): JSX.Element {
  const ctx = useMinigameContext();
  const { game, myBadge } = props;
  const [, setActiveGame] = useGameLikeWorkspace('game', 'active');
  const picked =
    ctx.embed && ctx.embedCtx.picked ? ctx.embedCtx.picked(game.id) : false;
  const actionSheetSettings = useGameLikeActionSheetSettings(
    game,
    'mini-card',
    ctx.pageType
  );

  const handleCardClick = () => {
    if (props.onGameClick) {
      props.onGameClick(game);
    } else {
      setActiveGame(game);
    }
  };

  const handleWithGamePack = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!ctx.embed) return;
    const fn = picked ? ctx.embedCtx.handleRemove : ctx.embedCtx.handleAdd;
    if (fn) fn(game);
  };

  return (
    <div
      className='flex flex-row items-center justify-between cursor-pointer hover:bg-lp-gray-002 rounded-xl p-2'
      onClick={handleCardClick}
    >
      <div className='relative overflow-hidden flex-shrink-0'>
        <GameCover game={game} className='w-22 h-12' myBadge={myBadge} />
      </div>
      <div className='flex flex-col mx-5 lg:w-1/2 w-25 flex-shrink-0'>
        <div
          className='text-base font-bold text-white truncate'
          title={game.name}
        >
          {game.name}
        </div>
        <div className='text-sms'>
          <span className='text-white'>
            {game.blocksCount} {pluralize('Block', game.blocksCount)}
          </span>
          <span className='text-white mx-1.5'>·</span>
          <span className='text-secondary'>
            {game.recordingsCount} {pluralize('Rec', game.recordingsCount)}
          </span>
          {OndVersionChecks(game.playbackVersion).ondV1Badge && (
            <>
              <span className='text-white mx-1.5'>·</span>
              <span className='text-tertiary'>OnD v1</span>
            </>
          )}
          {game.recordingVersionMismatch && (
            <>
              <span className='text-white mx-1.5'>·</span>
              <span className='text-red-003'>Rec Mismatch</span>
            </>
          )}
        </div>
      </div>
      <div className='ml-auto'></div>
      {!props.disableAction && (
        <div className='flex flex-row-reverse flex-shrink-0'>
          {ctx.embed ? (
            <>
              {ctx.embedCtx.picked && (
                <button
                  type='button'
                  className={`${
                    picked ? 'btn-delete' : 'btn-primary'
                  } w-7.5 h-7.5 rounded flex items-center justify-center text-xl font-medium`}
                  onClick={handleWithGamePack}
                >
                  {picked ? <DeleteIcon /> : '+'}
                </button>
              )}
            </>
          ) : (
            <GameActionSheet
              game={game}
              buttonClassName='w-7.5 h-7.5'
              settings={actionSheetSettings}
            />
          )}
        </div>
      )}
    </div>
  );
}
