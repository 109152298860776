import { useEffect, useState } from 'react';

import { EnumsPageName } from '@lp-lib/api-service-client/public';

import {
  FilterPresets,
  type GameLikeClickedProps,
  GameLikeFilterDropdown,
  useRedirectToSearch,
} from '../../components/Game/GameCenter';
import {
  EditCategoriesButton,
  useFeaturedTags,
} from '../../components/Tagging';
import { useUser } from '../../components/UserContext';
import { type GamesResponse, type Paginator } from '../../services/api-service';
import { type Game } from '../../types/game';
import { getTagLink, virtualTags } from '../../types/tag';
import { RoleUtils } from '../../types/user';
import { useMinigameContext } from './Context';
import { getPaginator } from './index';
import { Row } from './Row';

export function Library(props: GameLikeClickedProps<Game>): JSX.Element | null {
  const ctx = useMinigameContext();
  const isAdmin = RoleUtils.isAdmin(useUser());
  const { data: featuredTags } = useFeaturedTags(
    EnumsPageName.PageNameDiscoverGames,
    isAdmin
  );

  const [paginatorMap, setPaginatorMap] = useState<Map<
    number,
    Paginator<GamesResponse, Game>
  > | null>(null);

  useRedirectToSearch(!ctx.embed, 'game');

  useEffect(() => {
    const map = new Map<number, Paginator<GamesResponse, Game>>();
    featuredTags?.forEach((t) => {
      map.set(t.id, getPaginator(t.id));
    });
    setPaginatorMap(map);
    return () => {
      setPaginatorMap(null);
    };
  }, [featuredTags]);

  if (!paginatorMap) return null;

  return (
    <div className='pt-8 pb-2'>
      <header className='px-10 py-4 text-white flex justify-between items-center'>
        <div className='text-3xl font-bold'>Minigame Library</div>
        <div className='flex items-center gap-4'>
          <GameLikeFilterDropdown
            type='game'
            filter={FilterPresets.duration}
            getSelectedOptions={(filterOptions) => filterOptions.duration}
          />
          {isAdmin && !ctx.embed && (
            <EditCategoriesButton
              context={EnumsPageName.PageNameDiscoverGames}
            />
          )}
        </div>
      </header>

      {featuredTags?.map((t, i) => {
        const paginator = paginatorMap.get(t.id);
        if (!paginator) return null;
        return (
          <Row
            key={t.id}
            tag={t}
            paginator={paginator}
            lazy={i >= 2}
            linkTo={getTagLink(t)}
            headerNarrowed
            hideIfEmpty={t.id === virtualTags.untagged.id}
            onGameClick={props.onItemClick}
          />
        );
      })}
    </div>
  );
}
