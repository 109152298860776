import {
  CreateGameModal,
  DeleteGameModal,
  DuplicateGameModal,
  PublishGameModal,
} from '../../components/Game/GameBaseEditor';
import { GameBlockEditor } from '../../components/Game/GameBlockEditor';
import { useGameLikeWorkspace } from '../../components/Game/GameCenter';
import { GameDetailCardModal } from '../../components/Game/GameDetailCard';
import { useEditingGameId } from '../../components/Game/GameEditorStore';

export const GameCRUDUtility = (): JSX.Element => {
  const editingGameId = useEditingGameId();
  const [deletingGame] = useGameLikeWorkspace('game', 'delete');
  const [creatingGame] = useGameLikeWorkspace('game', 'create');
  const [activeGame] = useGameLikeWorkspace('game', 'active');
  const [publishingGame] = useGameLikeWorkspace('game', 'publish');
  const [duplicatingGame] = useGameLikeWorkspace('game', 'duplicate');
  const [editingGamePack] = useGameLikeWorkspace('gamePack', 'edit');
  return (
    <>
      {!editingGamePack && activeGame && <GameDetailCardModal />}
      {editingGameId && <GameBlockEditor />}
      {deletingGame && <DeleteGameModal />}
      {creatingGame && <CreateGameModal />}
      {publishingGame && <PublishGameModal />}
      {duplicatingGame && <DuplicateGameModal />}
    </>
  );
};
