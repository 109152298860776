import { useParams } from '@remix-run/react';
import chunk from 'lodash/chunk';
import { useMemo } from 'react';

import {
  type GameLikeClickedProps,
  MiniGameCard,
  toGameLikeFilters,
  useGameLikeEmitterForList,
  useGameLikeEventEmitter,
  useGameLikeTagPaginator,
  useSetupGameLikeSearch,
} from '../../components/Game/GameCenter';
import {
  GameLikeListWrapper,
  useGameLikeListLoader,
} from '../../components/Game/GameCenter/List';
import { useTitle } from '../../hooks/useTitle';
import {
  apiService,
  type GamesResponse,
  type Paginator,
} from '../../services/api-service';
import { type Game, GameLikeQueryType } from '../../types/game';
import { type Tag } from '../../types/tag';
import { makeTitle } from '../../utils/common';
import { getGridStyle } from '../../utils/css';
import { useGameLoader, useMinigameContext } from './Context';

interface GameListProps {
  type: GameLikeQueryType;
  paginator: Paginator<GamesResponse, Game>;
  keyword?: string | number | null;
  breadcrumb?: string;
  onGameClick?: (game: Game) => void;
  filter?: { enabled?: boolean; visible?: boolean };
}

interface ParamTypes {
  slug: string;
}

export const GameList = (props: GameListProps): JSX.Element => {
  const { type, keyword, breadcrumb, paginator, filter } = props;
  const ctx = useMinigameContext();
  const emitter = useGameLikeEventEmitter('game');
  const { items, dao, state, error, handleRetry, handleLoadMore } =
    useGameLikeListLoader<GamesResponse, Game>(paginator);
  useGameLikeEmitterForList(emitter, dao, type, keyword);
  const loader = useGameLoader({ useDynamicRows: true });

  return (
    <GameLikeListWrapper
      type='game'
      queryType={type}
      breadcrumbs={ctx.breadcrumbs}
      breadcrumb={breadcrumb}
      filter={filter}
      showEmptyMessage={
        !paginator.hasMore() && items.length === 0 && !state.isRunning
      }
      showErrorMessage={!!error}
      canLoad={state.isStarted && !state.isRunning && !error}
      handleRetry={handleRetry}
      handleLoad={handleLoadMore}
    >
      <div className='pt-4'>
        {chunk(items, ctx.gridSettings.total).map((slice, i) => (
          <div
            key={i}
            className='grid gap-3'
            style={{ gridTemplateColumns: getGridStyle(ctx.gridSettings.cols) }}
          >
            {slice.map((g) => (
              <MiniGameCard
                key={g.id}
                game={g}
                myBadge={type === GameLikeQueryType.Search}
                onGameClick={props.onGameClick}
              />
            ))}
          </div>
        ))}
        {state.isRunning && loader}
      </div>
    </GameLikeListWrapper>
  );
};

export const MyGameList = (props: GameLikeClickedProps<Game>): JSX.Element => {
  const ctx = useMinigameContext();
  const paginator = useMemo(() => apiService.game.getMyGames(), []);
  useTitle(makeTitle('My Minigames'), { disable: ctx.embed });
  return (
    <GameList
      type={GameLikeQueryType.My}
      paginator={paginator}
      breadcrumb={'My Minigames'}
      onGameClick={props.onItemClick}
    />
  );
};

export const TagGameList = (
  props: GameLikeClickedProps<Game> & { tag?: Tag }
): JSX.Element | null => {
  const ctx = useMinigameContext();
  const { slug } = useParams<keyof ParamTypes>();
  useTitle(makeTitle(`#${slug}`), { disable: ctx.embed });
  const { inited, tag, paginator } = useGameLikeTagPaginator({
    slug: slug ?? '',
    getPaginatorByTagId: useMemo(
      () => apiService.game.getGamesByTagId.bind(apiService.game),
      []
    ),
    initTag: props.tag,
  });

  if (!inited || !tag || !paginator) {
    return null;
  }

  return (
    <GameList
      type={GameLikeQueryType.ByTags}
      keyword={tag.id}
      breadcrumb={tag.name}
      paginator={paginator}
      onGameClick={props.onItemClick}
    />
  );
};

export const SearchGameList = (
  props: GameLikeClickedProps<Game> & {
    search?: string;
    primeOnly?: boolean;
  }
): JSX.Element | null => {
  const ctx = useMinigameContext();
  const { q, breadcrumb, inited, showFilter, filterOptions } =
    useSetupGameLikeSearch({
      type: 'game',
      embed: ctx.embed,
      search: props.search,
    });
  useTitle(makeTitle(q), { disable: ctx.embed });

  const paginator = useMemo(() => {
    return apiService.game.searchGames(
      q,
      toGameLikeFilters(filterOptions),
      props.primeOnly
    );
  }, [q, filterOptions, props.primeOnly]);

  if (!inited) return null;

  return (
    <GameList
      type={GameLikeQueryType.Search}
      keyword={q}
      breadcrumb={breadcrumb}
      paginator={paginator}
      filter={{ enabled: true, visible: showFilter }}
      onGameClick={props.onItemClick}
    />
  );
};

export const UntaggedGameList = (
  props: GameLikeClickedProps<Game>
): JSX.Element => {
  const ctx = useMinigameContext();
  const paginator = useMemo(() => apiService.game.getUntaggedPrimeGames(), []);
  useTitle(makeTitle('Not Featured'), { disable: ctx.embed });
  return (
    <GameList
      type={GameLikeQueryType.Untagged}
      paginator={paginator}
      breadcrumb={'Not Featured'}
      onGameClick={props.onItemClick}
    />
  );
};
