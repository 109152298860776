import { Link } from '@remix-run/react';
import { plural } from 'pluralize';
import { type ReactNode, useCallback, useEffect } from 'react';
import { useToggle } from 'react-use';
import { Waypoint } from 'react-waypoint';

import { type Repository, useArrayState } from '../../../hooks/useArrayState';
import {
  type TransformedAsyncCallState,
  useAsyncCall,
} from '../../../hooks/useAsyncCall';
import { type Paginable, type Paginator } from '../../../services/api-service';
import { type GameLike, GameLikeLabelMap } from '../../../types/game';
import { type Tag } from '../../../types/tag';
import { DoubleRightArrow } from '../../icons/Arrows';
import { GameIcon } from '../../icons/GameIcon';
import { type GameLikeContext } from './Context';
import { ErrorMessage } from './Utilities';

export type RowProps = {
  linkTo: string;
  tag: Tag;
  creatable?: boolean;
  headerText?: string;
  headerNarrowed?: boolean;
  hideIfEmpty?: boolean;
  lazy?: boolean;
  pinnedManager?: JSX.Element;
};

export function Navigate<T extends GameLike>(props: {
  ctx: GameLikeContext<T>;
  tag: Tag;
  linkTo: string;
  children: React.ReactNode;
}): JSX.Element {
  const { ctx, tag, linkTo } = props;
  if (ctx.embed) {
    const handleClick = () => {
      ctx.updateEmbedCtx({ tag: tag });
    };
    return (
      <button
        className='btn flex items-center justify-center'
        onClick={handleClick}
      >
        {props.children}
      </button>
    );
  } else {
    return (
      <Link
        className='flex items-center justify-center'
        to={`${ctx.routePrefix}${linkTo}`}
      >
        {props.children}
      </Link>
    );
  }
}

export function RowWrapper<T extends GameLike>(
  props: RowProps & {
    type: T['type'];
    ctx: GameLikeContext<T>;
    state: TransformedAsyncCallState;
    error: Error | null;
    loader: ReactNode;
    children: ReactNode;
    handleCreate: () => void;
    handleLoad: () => void;
    handleRetry: () => void;
    isEmpty?: boolean;
    headerSpacing?: string;
    rowSpacing?: string;
  }
): JSX.Element | null {
  const { state, handleLoad } = props;

  const [entered, setEntered] = useToggle(!props.lazy);

  useEffect(() => {
    if (entered) handleLoad();
  }, [handleLoad, entered]);

  let body = null;
  let flex = false;
  if (props.error) {
    body = (
      <ErrorMessage
        text='Something went wrong'
        handleRetry={props.handleRetry}
      />
    );
    flex = true;
  } else {
    if (props.isEmpty) {
      if (props.creatable) {
        body = (
          <p className='my-12 text-center'>
            Create your first {GameLikeLabelMap[props.type]} or add
            <br />
            one from the library below
          </p>
        );
      } else {
        body = (
          <ErrorMessage
            text={`No ${plural(GameLikeLabelMap[props.type])} found`}
            handleRetry={props.handleRetry}
          />
        );
      }
      flex = true;
    } else {
      body = props.children;
    }
  }

  if (props.isEmpty && props.hideIfEmpty && state.isDone) {
    return null;
  }

  return (
    <div className='px-10 py-4 flex flex-col'>
      <header
        className={`flex flex-row justify-between ${
          props.headerSpacing ?? 'mb-2'
        }`}
      >
        <div className='text-white group flex items-center justify-center'>
          <Navigate ctx={props.ctx} linkTo={props.linkTo} tag={props.tag}>
            <p
              className={`${
                props.headerNarrowed ? 'text-2xl' : 'text-3xl'
              } font-bold`}
            >
              {props.headerText || props.tag.name}
            </p>
            {!props.isEmpty && (
              <div className='text-base ml-8 flex flex-row items-center opacity-0 group-hover:opacity-100'>
                <p className='mr-3'>View All</p> <DoubleRightArrow />
              </div>
            )}
          </Navigate>
          {props.pinnedManager}
        </div>
        {props.creatable && (
          <button
            type='button'
            className='btn-primary h-12 w-60 flex items-center justify-center'
            onClick={props.handleCreate}
          >
            <GameIcon />
            <p className='ml-2'>Create {GameLikeLabelMap[props.type]}</p>
          </button>
        )}
      </header>
      <Waypoint onEnter={() => setEntered(true)} fireOnRapidScroll>
        <section className='flex items-center justify-center text-white h-full min-h-10'>
          {state.isStarted && (
            <>
              {state.isRunning && (
                <div className='w-full h-full flex items-center mt-6'>
                  {props.loader}
                </div>
              )}
              {!state.isRunning && (
                <div
                  className={`
                    w-full
                    ${props.rowSpacing ?? 'mt-3'}
                    ${flex ? 'flex items-center justify-center' : ''}
                  `}
                >
                  {body}
                </div>
              )}
            </>
          )}
        </section>
      </Waypoint>
    </div>
  );
}

export function useGameLikeRowLoader<P extends Paginable, T extends GameLike>(
  paginator: Paginator<P, T>
): {
  items: T[];
  dao: Repository<T>;
  state: TransformedAsyncCallState;
  error: Error | null;
  handleRetry: () => void;
  handleLoad: () => void;
} {
  const [items, setItems, dao] = useArrayState<T>({
    prepend: true,
    compare: (a, b) => a.id === b.id,
  });

  const {
    state,
    error,
    call: load,
    reset,
  } = useAsyncCall(
    useCallback(async () => {
      const next = await paginator.next();
      setItems(next);
    }, [paginator, setItems])
  );

  useEffect(() => {
    return () => {
      setItems([]);
      paginator.reset();
      reset();
    };
  }, [paginator, setItems, reset]);

  const handleRetry = () => {
    paginator.reset();
    load();
  };

  return {
    items,
    dao,
    state: state.transformed,
    error,
    handleRetry,
    handleLoad: load,
  };
}
