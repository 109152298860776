import { useMemo } from 'react';

import { type GameLikeClickedProps } from '../../components/Game/GameCenter';
import { apiService } from '../../services/api-service';
import { type Game } from '../../types/game';
import { virtualTags } from '../../types/tag';
import { useMinigameContext } from './Context';
import { Row } from './Row';

export function MyGames(props: GameLikeClickedProps<Game>): JSX.Element {
  const ctx = useMinigameContext();
  const paginator = useMemo(() => apiService.game.getMyGames(), []);
  return (
    <div className='pt-8 pb-6 border-b border-secondary'>
      <Row
        creatable={!ctx.embed}
        tag={virtualTags.my}
        paginator={paginator}
        lazy={false}
        linkTo='/my'
        headerText='My Minigames'
        onGameClick={props.onItemClick}
      />
    </div>
  );
}
