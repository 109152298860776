import React, { useCallback, useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

import { useAsyncCall } from '../../hooks/useAsyncCall';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { apiService } from '../../services/api-service';
import {
  type Game,
  GameActionType,
  type GameLikeActionSheetSettings,
} from '../../types/game';
import { NotificationType } from '../../types/notification';
import { RoleUtils } from '../../types/user';
import { uuidv4 } from '../../utils/common';
import { useTriggerBrandImportModal } from '../Brand/BrandImport';
import { DeleteIcon } from '../icons/DeleteIcon';
import { DownloadIcon } from '../icons/DownloadIcon';
import { DuplicateIcon } from '../icons/DuplicateIcon';
import { EditIcon } from '../icons/EditIcon';
import { GameModeIcon } from '../icons/GameModeIcon';
import { OptionsIcon } from '../icons/OptionsIcon';
import { ShareIcon } from '../icons/ShareIcon';
import { Loading } from '../Loading';
import { useNotificationDataSource } from '../Notification/Context';
import { useUser } from '../UserContext';
import { useGameLikeEventEmitter, useGameLikeWorkspace } from './GameCenter';
import { useGameEditorStore } from './GameEditorStore';

interface GameActionSheetProps {
  game: Game;
  disabled?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
  stopPropagation?: boolean;
  buttonClassName?: string;
  settings?: GameLikeActionSheetSettings;
}

const itemMeunStyle =
  'btn w-full h-8 px-2 hover:bg-dark-gray border-0 rounded-lg flex flex-row justify-start items-center text-3xs';

const GameActionSheet = (props: GameActionSheetProps): JSX.Element => {
  const options = Object.assign({}, { stopPropagation: true }, props);
  const store = useGameEditorStore();
  const [showItemMenu, setShowItemMenu] = useState(false);
  const [, setDeletingGame] = useGameLikeWorkspace('game', 'delete');
  const [, setPublishingGame] = useGameLikeWorkspace('game', 'publish');
  const [, setDuplicatingGame] = useGameLikeWorkspace('game', 'duplicate');
  const notificationDataSource = useNotificationDataSource();
  const emitter = useGameLikeEventEmitter('game');
  const user = useUser();
  const isAdmin = RoleUtils.isAdmin(user);
  const triggerBrandImportModal = useTriggerBrandImportModal();

  const {
    state: { transformed: duplcateGameState },
    call: duplcateGame,
  } = useAsyncCall(
    useCallback(async (gameId: string) => {
      const { game, blocks } = (await apiService.game.duplicate(gameId)).data;
      game.blocks = blocks;
      return game;
    }, [])
  );

  const {
    state: { transformed: forkGameState },
    call: forkGame,
  } = useAsyncCall(
    useCallback(async (gameId: string) => {
      const { game, blocks } = (await apiService.game.duplicate(gameId, true))
        .data;
      game.blocks = blocks;
      return game;
    }, [])
  );

  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    tooltipRef,
    triggerRef,
    visible,
  } = usePopperTooltip({
    trigger: 'click',
    placement: 'bottom-end',
    visible: showItemMenu,
  });

  useOutsideClick(tooltipRef, () => setShowItemMenu(false), triggerRef);

  const onContainerClick = (e: React.MouseEvent) => {
    if (options.stopPropagation) e.stopPropagation();
  };

  const onClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (options.onClick) options.onClick();
    setShowItemMenu(!showItemMenu);
  };

  const onEditingGame = async () => {
    await store.setEditingGame(options.game, { fetchBlocks: true });
    setShowItemMenu(false);
  };

  const onDuplicateGame = async () => {
    if (options.game.isPrime) {
      setDuplicatingGame(options.game);
      setShowItemMenu(false);
    } else {
      const game = await duplcateGame(options.game.id);
      if (!game) return;
      emitter.emit('duplicated', game);
      setShowItemMenu(false);
    }
  };

  const onDeleteGame = () => {
    setDeletingGame(options.game);
    setShowItemMenu(false);
  };

  const onPublishGame = () => {
    setPublishingGame(options.game);
    setShowItemMenu(false);
  };

  const onAddToMyGames = async () => {
    const game = await forkGame(options.game.id);
    if (!game) return;
    emitter.emit('duplicated', game);
    notificationDataSource.send({
      id: uuidv4(),
      toUserClientId: '',
      type: NotificationType.GameAction,
      createdAt: Date.now(),
      metadata: {
        actionType: GameActionType.CopyPrimeGame,
        game: game,
      },
    });
    setShowItemMenu(false);
  };

  const onConvertToBrand = () => {
    triggerBrandImportModal(options.game);
  };

  return (
    <div onClick={onContainerClick}>
      <button
        className={`${
          options.buttonClassName || ''
        } btn flex flex-row justify-center items-center text-white bg-black rounded-lg border border-solid border-secondary hover:bg-light-gray ${
          props.disabled ? 'cursor-not-allowed pointer-events-on' : ''
        }`}
        onMouseEnter={options.onMouseEnter}
        onMouseLeave={options.onMouseLeave}
        onClick={onClick}
        ref={setTriggerRef}
        type='button'
        disabled={props.disabled}
      >
        <OptionsIcon />
      </button>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: `w-auto h-auto border border-secondary rounded-lg text-white flex flex-col p-1 z-50 transition-opacity bg-black whitespace-nowrap`,
          })}
        >
          {options.settings?.edit && (
            <button
              type='button'
              onMouseUp={onEditingGame}
              className={itemMeunStyle}
            >
              <EditIcon />
              <div className='ml-2'>
                Edit {options.game.isPrime && 'Prime'} Minigame
              </div>
            </button>
          )}
          {options.settings?.duplicate && (
            <button
              type='button'
              onMouseUp={onDuplicateGame}
              className={itemMeunStyle}
            >
              {duplcateGameState.isRunning && (
                <Loading
                  imgClassName='w-3.5 w-3.5'
                  text=''
                  containerClassName='mr-2'
                />
              )}
              <DuplicateIcon />
              <div className='ml-2'>Duplicate</div>
            </button>
          )}
          {options.settings?.publish && isAdmin && (
            <button
              type='button'
              onMouseUp={onPublishGame}
              className={itemMeunStyle}
            >
              <ShareIcon />
              <div className='ml-2'>Publish to Library</div>
            </button>
          )}
          {options.settings?.addToMySpace && options.game.isPrime && (
            <button
              type='button'
              onMouseUp={onAddToMyGames}
              className={itemMeunStyle}
            >
              {forkGameState.isRunning && (
                <Loading
                  imgClassName='w-3.5 w-3.5'
                  text=''
                  containerClassName='mr-2'
                />
              )}
              <DownloadIcon className='w-3.5 h-3.5 fill-current' />
              <div className='ml-2'>Add to My Minigames</div>
            </button>
          )}
          {options.settings?.convertToBrand && (
            <button
              type='button'
              onMouseUp={onConvertToBrand}
              className={itemMeunStyle}
            >
              <GameModeIcon className='w-3.5 h-3.5 fill-current' />
              <div className='ml-2'>Convert to Brand</div>
            </button>
          )}
          {options.settings?.delete && (
            <button
              type='button'
              onMouseUp={onDeleteGame}
              className={`${itemMeunStyle} text-red-002`}
            >
              <DeleteIcon />
              <div className='ml-2'>
                Delete {options.game.isPrime && 'Prime'} Minigame
              </div>
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export { GameActionSheet };
