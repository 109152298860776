import '../../assets/scss/slick-theme.scss';
import '../../assets/scss/slick.scss';

import chunk from 'lodash/chunk';
import Slider from 'react-slick';

import {
  MiniGameCard,
  useGameLikeEmitterForDiscover,
  useGameLikeEventEmitter,
  useGameLikeWorkspace,
} from '../../components/Game/GameCenter';
import {
  type RowProps,
  RowWrapper,
  useGameLikeRowLoader,
} from '../../components/Game/GameCenter/Row';
import { type GamesResponse, type Paginator } from '../../services/api-service';
import { type Game } from '../../types/game';
import { TagUtils } from '../../types/tag';
import { getGridStyle } from '../../utils/css';
import {
  type GamePickedProps,
  useGameLoader,
  useGameSliderSettings,
  useMinigameContext,
} from './Context';

function MiniGameCardGrid(
  props: GamePickedProps & { games: Game[] }
): JSX.Element {
  const ctx = useMinigameContext();
  return (
    <div
      className='grid gap-3 mr-10'
      style={{ gridTemplateColumns: getGridStyle(ctx.gridSettings.cols) }}
    >
      {props.games.map((g) => (
        <MiniGameCard key={g.id} game={g} onGameClick={props.onGameClick} />
      ))}
    </div>
  );
}

type Props = GamePickedProps &
  RowProps & {
    paginator: Paginator<GamesResponse, Game>;
  };

export const Row = (props: Props): JSX.Element | null => {
  const ctx = useMinigameContext();
  const { paginator, onGameClick, ...rest } = props;
  const { items, dao, state, error, handleRetry, handleLoad } =
    useGameLikeRowLoader(paginator);
  const [, setCreatingGame] = useGameLikeWorkspace('game', 'create');
  const sliderSettings = useGameSliderSettings();
  const loader = useGameLoader({
    gamesCount: TagUtils.getPrimeGamesCount(rest.tag),
  });
  const emitter = useGameLikeEventEmitter('game');

  useGameLikeEmitterForDiscover(emitter, dao, rest.tag.id);

  const grids = chunk(items, ctx.gridSettings.total).map((slice, idx) => (
    <MiniGameCardGrid key={idx} games={slice} onGameClick={onGameClick} />
  ));
  const body =
    items.length >= ctx.gridSettings.total ? (
      <Slider {...sliderSettings}>{grids}</Slider>
    ) : (
      <>{grids}</>
    );

  return (
    <RowWrapper
      {...rest}
      ctx={ctx}
      type='game'
      loader={loader}
      state={state}
      error={error}
      handleCreate={() => setCreatingGame(true)}
      handleLoad={handleLoad}
      handleRetry={handleRetry}
      isEmpty={items.length === 0}
    >
      {body}
    </RowWrapper>
  );
};
